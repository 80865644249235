<div class="dashboard-right-inner">
  <Breadcrumb />
  <app-loader v-if="isEmailLogListLoading || isLoading" />
  <div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div class="px-20 px-10-sm">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100-vsm pt-12 pb-16 w-100 border-bottom-normal-1 mb-20"
        >
          <h2 class="font-18 font-w-600 mb-2 mb-sm-0">Email Log</h2>
          <div class="w-100-vsm d-flex gap-3"></div>
        </div>
        <h1>{{part}}</h1>
      </div>
    </div>
    <div class="dashboard-content py-0 px-20 px-10-sm">
      <div
        class="d-flex flex-wrap align-items-start justify-content-between w-100 mb-36"
      >
        <div
          class="search-box-wrap mb-3 mb-md-2 me-0 me-md-4 d-flex align-items-center w-100-vsm"
        >
          <span class="k-icon k-i-zoom"></span>
          <span class="k-textbox k-grid-search k-display-flex">
            <k-input
              :class="'mb-0 w-100-vsm w-350 w-100-vsm'"
              placeholder="Search by Email Address"
              v-model="searchQuery"
              @input="debounceSearch"
            ></k-input>
          </span>
        </div>
        <div class="d-flex flex-wrap w-100-1630">
          <div
            class="me-0 me-sm-4 mb-2 mb-md-1 w-100-vsm d-flex flex-wrap flex-vsm-wrap flex-sm-nowrap align-items-center"
          >
            <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm"
              >Select Source</label
            >
            <AppDropdownList
              :searchable="false"
              :data-items="sourceOptions"
              :textField="'text'"
              :dataItemKey="'id'"
              v-model="selectedSource"
              :class="'w-100-vsm h-38 drop-icon-2'"
              @updateDropdownValue="
                (value) => handleValueChange(value, 'selectedSource')
              "
            />
          </div>
          <!-- <div class="mr-24 mb-2 mb-md-1 w-100-vsm d-flex flex-wrap flex-vsm-wrap flex-sm-nowrap align-items-center"
          >
            <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm"
              >Select Status</label
            >
            <AppDropdownList
              :searchable="false"
              :data-items="allStatuses"
              :textField="'text'"
              :dataItemKey="'id'"
              v-model="selectedStatus"
              :class="'w-100-vsm h-38 drop-icon-2'"
              @updateDropdownValue="
                (value) => handleValueChange(value, 'selectedStatus')
              "
            />
          </div> -->
          <div
            class="me-0 me-sm-4 mb-2 mb-md-1 w-100-vsm d-flex flex-wrap flex-vsm-wrap flex-sm-nowrap align-items-center"
          >
            <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm"
              >Select Date</label
            >
            <AppDateRangePicker
              :dateRangeProps="[this.startDate,this.endDate]"
              @dateSelected="handleDateSelected"
              @error="handleError"
              :disableFutureDates="true"
              prevDateDisabledBefore="90"
              :class="'h-38 drop-icon-2 w-100-vsm'"
              :style="{ width: '375px' }"
            >
            </AppDateRangePicker>
          </div>
          <kbutton
            @click="clearFilters"
            :fill-mode="'outline'"
            :class="'w-100-vsm h-38 border-normal-1 text-color bg-white hvr-bg-primary'"
            :theme-color="'primary'"
            >Clear</kbutton
          >
          <!-- <kbutton :theme-color="'primary'" :class="'w-100-vsm h-38'"
            >Search</kbutton
          > -->
        </div>
      </div>
      <div v-if="emailLogsList?.length" class="custom-table-wrap">
        <div class="custom-table">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Email Subject</th>
                <th>Source</th>
                <th>Source Name</th>
                <th>Sent Date</th>
                <th>Sent Time</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(log, index) in emailLogsList" :key="log?.id">
                <tr @click="onLogClickHandler(log, index)">
                  <td style="cursor: pointer">
                    <a href="#">{{log?.email}}</a>
                  </td>
                  <td style="cursor: pointer">{{log?.subject}}</td>
                  <td style="cursor: pointer">{{log?.sourceType}}</td>
                  <td style="cursor: pointer">{{log?.sourceOrigin}}</td>
                  <td style="cursor: pointer">
                    {{this.generateDate(log?.utcCreatedAt)}}
                  </td>
                  <td style="cursor: pointer">
                    {{this.generateTime(log?.utcCreatedAt)}}
                  </td>
                  <td style="cursor: pointer">
                    <span
                      class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8"
                      :class="this.statusColors[log?.emailStatus]"
                      >{{this.statusTexts[log?.emailStatus]}}</span
                    >
                  </td>
                  <td style="cursor: pointer">
                    <span
                      class="show-hide-arrow"
                      :class="log?.hasDetails ? 'up' : 'down'"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9393 4.93945L7.99997 8.87883L4.06056 4.93945L3 6.00011L7.99997 11.0001L13 6.00011L11.9393 4.93945Z"
                          fill="#24243A"
                        />
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr v-if="log?.hasDetails && log?.details?.length">
                  <td colspan="8">
                    <div class="email-log-info-wrap">
                      <div
                        class="eliw-top-info d-flex gap-2 justify-content-between"
                      >
                        <div class="eliw-top-single">
                          <p class="font-13 font-w-500 m-0">Email Subject</p>
                          <h6 class="font-14 font-w-500 m-0">
                            {{log?.details[0]?.subject}}
                          </h6>
                        </div>
                        <div class="eliw-top-single">
                          <p class="font-13 font-w-500 m-0">Source</p>
                          <h6 class="font-14 font-w-500 m-0">
                            {{log?.details[0]?.sourceType}}
                          </h6>
                        </div>
                        <div
                          v-if="log?.details[0]?.sourceOrigin"
                          class="eliw-top-single"
                        >
                          <p class="font-13 font-w-500 m-0">Name</p>
                          <h6 class="font-14 font-w-500 m-0">
                            {{log?.details[0]?.sourceOrigin}}
                          </h6>
                        </div>
                      </div>
                      <div class="eliw-timeline-wrap">
                        <div class="eliw-timeline d-flex justify-content-between">
                          <div
                            v-for="(data, index) in log?.details"
                            :key="data?.id"
                            class="eliw-timeline-single"
                          >
                            <span
                              class="campaign-status styled-2 font-14 font-w-600 radius-100 px-8 mb-10 d-inline-flex gap-2 align-items-center"
                              :class="this.statusColors[data?.emailStatus]"
                            >
                              <span class="dot m-0" :class="this.statusColors[data?.emailStatus]"></span>
                              {{this.statusTexts[data?.emailStatus]}}
                            </span>
                            <p class="font-12 m-0 text-center campaign-date">
                              {{this.generateDate(data?.utcCreatedAt)}}
                            </p>
                            <p class="font-12 m-0 text-center campaign-time">
                              {{this.generateTime(data?.utcCreatedAt)}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="log?.hasDetails && !log?.details?.length">
                  <td>Empty state</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div style="margin-top: 60px; background: #ffffff"></div>
          <pager
            :class="'bg-white border-0 pager-fixed-down'"
            :responsive="true"
            :skip="initialPageState.skip"
            :take="initialPageState.take"
            :total="initialPageState.total"
            :button-count="initialPageState.buttonCount"
            :info="initialPageState.info"
            :type="initialPageState.type"
            :page-sizes="
            initialPageState.pageSizes
              ? initialPageState.pageSizeDefs
              : undefined
          "
            :previous-next="initialPageState.previousNext"
            @pagechange="handlePageChange"
          >
          </pager>
        </div>
      </div>
      <div
        v-if="!emailLogsList?.length && !isEmailLogListLoading"
        class="dashboard-content min-height-calc-100-240 d-flex justify-content-center align-items-center flex-column"
      >
        <div class="mb-10">
          <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.5 20.25C23.432 20.25 24.1875 21.0055 24.1875 21.9375V29.8125C24.1875 30.7445 23.432 31.5 22.5 31.5C21.568 31.5 20.8125 30.7445 20.8125 29.8125V21.9375C20.8125 21.0055 21.568 20.25 22.5 20.25ZM22.5 18C23.7426 18 24.75 16.9926 24.75 15.75C24.75 14.5074 23.7426 13.5 22.5 13.5C21.2574 13.5 20.25 14.5074 20.25 15.75C20.25 16.9926 21.2574 18 22.5 18ZM22.5 6.1875C31.5091 6.1875 38.8125 13.4909 38.8125 22.5C38.8125 26.3967 37.4462 29.9743 35.1665 32.7798L46.1932 43.8068C46.8523 44.4658 46.8523 45.5342 46.1932 46.1932C45.5941 46.7923 44.6567 46.8468 43.996 46.3566L43.8068 46.1932L32.7798 35.1665C29.9743 37.4462 26.3967 38.8125 22.5 38.8125C13.4909 38.8125 6.1875 31.5091 6.1875 22.5C6.1875 13.4909 13.4909 6.1875 22.5 6.1875ZM22.5 9.5625C15.3548 9.5625 9.5625 15.3548 9.5625 22.5C9.5625 29.6452 15.3548 35.4375 22.5 35.4375C29.6452 35.4375 35.4375 29.6452 35.4375 22.5C35.4375 15.3548 29.6452 9.5625 22.5 9.5625Z"
              fill="#ADB2BD"
            />
          </svg>
        </div>
        <h5 class="font-16 font-w-600 mb-10">
          Find Your Email Activity Effortlessly
        </h5>
        <p class="font-14">
          No results found. Try expanding your search using fewer criteria
        </p>
      </div>
    </div>
  </div>
</div>
