<template>
  <dropdownlist
    :class="classNames"
    :mandatory="mandatory"
    :data-items="item"
    :text-field="textField"
    :data-item-key="dataItemKey"
    :value="value"
    :filterable="searchable"
    :filter="filterValue"
    @change="handleDropdownChange"
    @filterchange="filterChange"
    :fill-mode="btnFillMode"
    :disabled="disabled"
    :style="{ width: width }"
    :default-item="{[textField]: defaultText, [dataItemKey] : -1}"
  >
  </dropdownlist>
</template>

<script>
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export default {
  name: "AppDropdownList",
  components: {
    dropdownlist: DropDownList,
  },
  watch: {
    dataItems : {
      immediate: true,
      handler(currentValue, oldValue) {
        this.item = currentValue;
      }
    }
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    dataItems: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
      required: false,
    },
    classNames: {
      type: String,
      default: "",
      required: false,
    },
    textField: {
      type: String,
      default: "text",
    },
    dataItemKey: {
      type: String,
      default: "id",
    },
    btnFillMode: {
      type: String,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "190px",
    },
    defaultText: {
      type: String,
      default: null,
    }
  },
  data: function () {
    return {
      item: [],
      filterValue: "",
    };
  },
  created() {
    this.item = this.dataItems || [];
    this.itemCopy = this.dataItems || [];
  },
  methods: {
    handleDropdownChange(event) {
      const data = event.value;
      this.$emit("updateDropdownValue", data);
    },
    filterChange(event) {
      this.filterValue = event.filter.value;
      const dataMain = this.$props.dataItems.slice();
      event.filter.value = event.filter.value.trim();
      this.item = filterBy(dataMain, event.filter);
    },
  },
};
</script>

<style scoped></style>
