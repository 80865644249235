<template src="./emailLog.html"></template>
<style lang="scss">
@import "./emailLog.scss";
</style>

<script>
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import {
  getEmailLogsService,
  getIndividualEmailLogService,
} from "@/services/clubDetails/emailLog.service";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import AppDropdownList from "@/components/common/AppDropdownList/AppDropdownList.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { Pager } from "@progress/kendo-vue-data-tools";
import AppDateRangePicker from "@/components/common/AppDateRangePicker/AppDateRangePicker.vue";
import moment from "moment";

export default {
  components: {
    Breadcrumb,
    "k-input": Input,
    dropdownlist: DropDownList,
    kbutton: Button,
    buttongroup: ButtonGroup,
    AppDropdownList,
    AppLoader,
    Pager,
    AppDateRangePicker,
  },

  data() {
    return {
      moduleCode: "emailLog",
      startDate: "",
      endDate: new Date(),
      clubId: this.$route.params.id,
      isEmailLogListLoading: false,
      isLoading: false,
      isLogDetailsLoading: false,
      emailLogsList: [],
      selectedLogIndex: null,
      prevSelectedLogIndex: null,
      initialPageState: {
        skip: 0,
        take: 10,
        buttonCount: 5,
        type: "numeric",
        info: true,
        previousNext: true,
        pageSizes: true,
        total: 100,
        pageSizeDefs: [10, 50, 100, 200, 500, 1000],
      },
      searchQuery: "",
      statusTexts: {
        1: "Not Sent",
        2: "Sending",
        3: "Sent",
        4: "Opened",
        5: "Clicked",
        6: "Delivered",
        7: "Bounced",
        8: "Scheduled",
        9: "Conversion",
      },
      statusColors: {
        1: "not-sent",
        2: "sending",
        3: "sent",
        4: "opened",
        5: "clicked",
        6: "delivered",
        7: "not-delivered",
        8: "scheduled",
        9: "conversion",
      },

      selectedStatus: {
        id: 0,
        text: "All Statuses",
        value: "All Statuses",
      },
      allStatuses: [
        {
          id: 0,
          text: "All Statuses",
          value: "All Statuses",
        },
        {
          id: 1,
          text: "Not Sent",
          value: "Not Sent",
        },
        {
          id: 2,
          text: "Sending",
          value: "Sending",
        },
        {
          id: 3,
          text: "Sent",
          value: "Sent",
        },
        {
          id: 4,
          text: "Opened",
          value: "Opened",
        },
        {
          id: 5,
          text: "Clicked",
          value: "Clicked",
        },
        {
          id: 6,
          text: "Delivered",
          value: "Delivered",
        },
        {
          id: 7,
          text: "Not Delivered",
          value: "Not Delivered",
        },
        {
          id: 8,
          text: "Scheduled",
          value: "Scheduled",
        },
        {
          id: 9,
          text: "Conversion",
          value: "Conversion",
        },
      ],
      sourceOptions: [
        {
          id: 1,
          text: "All Sources",
          value: "All Sources",
        },
        {
          id: 2,
          text: "Campaign",
          value: "Campaign",
        },
        {
          id: 3,
          text: "Digital Pass",
          value: "Digital Pass",
        },
        {
          id: 4,
          text: "Flow Automation",
          value: "Flow Automation",
        },
        {
          id: 5,
          text: "Voucher",
          value: "Voucher",
        },
      ],
      selectedSource: {
        id: 1,
        text: "All Sources",
        value: "All Sources",
      },
    };
  },

  created() {},
  watch: {},
  computed: {},
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view email logs.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.setDates();
    let payload = this.payloadGenerator();
    this.getEmailLogs(payload);
  },
  unmounted() {},
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: null,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: null,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: null,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: null,
        childItemCode: null,
      });
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getEmailLogs(this.payloadGenerator());
      }, 600);
    },
    isAnyFilterExist() {
      return (
        this.searchQuery ||
        this.selectedStatus.value !== "All Statuses" ||
        this.selectedSource.value !== "All Sources"
      );
    },
    setDates() {
      let date = new Date();
      this.startDate = new Date(date.setDate(date.getDate() - 30));
      this.endDate = new Date();
    },
    clearFilters() {
      this.searchQuery = "";
      this.selectedStatus = {
        id: 0,
        text: "All Statuses",
        value: "All Statuses",
      };
      this.selectedSource = {
        id: 1,
        text: "All Sources",
        value: "All Sources",
      };
      this.setDates();
      this.getEmailLogs(this.payloadGenerator());
    },
    payloadGenerator() {
      return {
        clubId: this.clubId,
        ...(this.selectedStatus.value !== "All Statuses" && {
          emailStatus: this.selectedStatus.id,
        }),
        ...(this.selectedSource.value !== "All Sources" && {
          sourceType: this.selectedSource.value,
        }),
        ...(this.startDate &&
          this.endDate && {
            fromDate: this.startDate,
            toDate: this.endDate,
          }),
        searchQuery: this.searchQuery,
        limit: this.initialPageState.take,
        offset: this.initialPageState.skip,
      };
    },
    handleValueChange(value, state) {
      this[state] = value;
      let payload = this.payloadGenerator();
      this.getEmailLogs(payload);
    },
    getEmailLogs(payload) {
      this.isEmailLogListLoading = true;
      getEmailLogsService(payload)
        .then((response) => {
          this.isEmailLogListLoading = false;
          this.emailLogsList = response.data?.data?.items;
          this.initialPageState.total = response.data?.data?.size;
          this.initialPageState.skip = response.data?.data?.offset;
          this.initialPageState.take = response.data?.data?.limit;
        })
        .catch((error) => {
          const message = error.response.data?.Status?.Message;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: message || "Something went wrong. Please try again later.",
            type: "error",
          });
          this.isEmailLogListLoading = false;
        });
    },
    getIndividualEmailLog(payload) {
      this.isLoading = true;
      getIndividualEmailLogService(payload)
        .then((response) => {
          this.isLoading = false;
          this.emailLogsList[this.selectedLogIndex].details =
            response.data?.data;
          this.emailLogsList[this.selectedLogIndex].hasDetails = true;
          console.log("this.c67", this.emailLogsList[this.selectedLogIndex]);

          if (
            this.prevSelectedLogIndex !== null &&
            this.prevSelectedLogIndex !== this.selectedLogIndex
          ) {
            this.emailLogsList[this.prevSelectedLogIndex].hasDetails = false;
          }
        })
        .catch((error) => {
          const message = error.response.data?.Status?.Message;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: message || "Something went wrong. Please try again later.",
            type: "error",
          });
          this.isLoading = false;
        });
    },
    onLogClickHandler(log, index) {
      if (log?.hasDetails) {
        this.emailLogsList[index].hasDetails = false;
        return;
      }

      // if (log?.details?.length > 0) {
      //   this.emailLogsList[index].hasDetails = true;
      //   return;
      // }

      this.prevSelectedLogIndex =
        this.selectedLogIndex !== this.prevSelectedLogIndex
          ? this.selectedLogIndex
          : null;
      this.selectedLogIndex = index;

      this.getIndividualEmailLog({
        clubId: this.clubId,
        transectionId: log.transectionId,
      });
    },
    handlePageChange: function (event) {
      this.initialPageState.skip = event.skip;
      this.initialPageState.take = event.take;

      let payload = this.payloadGenerator();
      this.getEmailLogs(payload);
    },
    generateDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    generateTime(date) {
      return moment(date).format("hh:mm A");
    },
    handleDateSelected({ start, end }) {
      this.startDate = start != null ? new Date(start) : null;
      this.endDate = end != null ? new Date(end) : null;

      if (!this.startDate && !this.endDate) {
        this.setDates();
      }

      this.getEmailLogs(this.payloadGenerator());
    },
  },
};
</script>
